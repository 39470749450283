import { useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Message } from "../profile/Interface";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { useLocation } from "react-router-dom";
import { Vendor } from "./vendorList";

interface VendorDetailsState {
  vendorGuid: string;
}

const VendorDetails = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const [img, setImg] = useState<string | undefined>("");
  const [doc, setDoc] = useState<string | undefined>("");

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const [vendor, setVendor] = useState<Vendor | null>(null);

  const handleViewClick = async () => {
    const modalElement = document.getElementById("image_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleDocViewClick = async () => {
    const modalElement = document.getElementById("pdf_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };
  const location = useLocation();
  const state = location.state as VendorDetailsState | undefined;
  const vendorGuid = state?.vendorGuid;

  const getVendorData = async () => {
    try {
      const url = `${API_URL}/vendor/findByGuid`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        vendorGuid: vendorGuid,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      setVendor(data.message);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (vendorGuid) getVendorData();
  }, [vendorGuid]);

  return (
    <>
      {" "}
      <h4 className="mb-5">Vendor Details</h4>
      <div
        className={`d-flex gap-3 ${
          window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
        }`}
      >
        <div className="card shadow-sm p-5">
          <div className="d-flex flex-row ms-5 mt-5">
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">Company Name</div>
              <div className="fs-7">{vendor?.companyName}</div>
            </div>
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">Vendor Name</div>
              <div>{vendor?.name}</div>
            </div>
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">Vendor Type</div>
              <div>
                {vendor?.vendorType === 1 ? "Domestic" : "International"}
              </div>
            </div>
          </div>
          <br />
          <div className="d-flex flex-row ms-5 mt-5">
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">VAT Number</div>
              <div className="fs-7">{vendor?.vatNumber}</div>
            </div>
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">Registered Email</div>
              <div>{vendor?.email}</div>
            </div>
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">Registered Phone Number</div>
              <div>
                {vendor?.isdCode}-{vendor?.mobileNumber}
              </div>
            </div>
          </div>
          <br />
          <div className="d-flex flex-row ms-5 mt-5">
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">Registered Address</div>
              <div className="fs-7">{vendor?.address}</div>
            </div>
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">Registered Country</div>
              <div className="fs-7">{vendor?.country}</div>
            </div>

            <div style={{ width: "400px" }}>
              {/* <div className="fw-bold fs-5">Registered Address</div>
              <div className="fs-7">{vendor?.address}</div> */}
            </div>
          </div>
          <br />
          <div className="d-flex flex-row ms-5 mt-5">
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">Bank Name</div>
              <div className="fs-7">{vendor?.bankName}</div>
            </div>
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">Account Number/IBAN</div>
              <div className="fs-7">{vendor?.accountNumber}</div>
            </div>
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">SWIFT/BIC</div>
              <div className="fs-7">{vendor?.bic}</div>
            </div>
          </div>
          <br />
        </div>

        <div className="modal" tabIndex={-1} id="image_modal">
          <div className="modal-dialog modal-dialog-centered">
            <div
              className="modal-content"
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              <div className="modal-body justify-content-center d-flex">
                <div
                  className="image-input image-input-outline"
                  data-kt-image-input="true"
                >
                  <img
                    className="image"
                    src={img}
                    style={{
                      borderRadius: "10px",
                      objectFit: "contain",
                      maxWidth: "500px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="pdf_modal"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered mw-700px">
            <div
              className="modal-content"
              style={{ maxHeight: "700px", overflow: "auto" }}
            >
              <div className="modal-header" id="kt_modal_add_user_header">
                <h3 className="fw-bold m-0">PDF Viewer</h3>

                {/* <div
                  className="btn btn-icon btn-sm btn-active-icon-primary"
                  data-kt-users-modal-action="close"
                  data-bs-dismiss="modal"
                >
                  <i className="ki-duotone ki-cross fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </div> */}
              </div>

              <div className="modal-body px-15">
                <embed
                  src={`${doc}#toolbar=0`}
                  type="application/pdf"
                  width="100%"
                  height="800px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <h4 className="mb-5">Vendor Payment History</h4>
      <div
        className="card shadow-sm p-5 mb-5 d-flex flex-column"
        // key={index}
      >
        <div className="d-flex flex-row">
          <p className="d-flex flex-row ">
            Invoice Number :{" "}
            <p
              style={{
                // fontSize: "15px",
                // fontWeight: "bold",
                borderRadius: "5px",
              }}
              className={`m-0 badge ms-2`}
            >
              INV00023
            </p>
          </p>
        </div>
        <div className="d-flex flex-row">
          <p className="d-flex flex-row m-0 text-nowrap">Status:</p>
          <div
            className="d-flex flex-row justify-content-between "
            style={{
              width: "1000px",
            }}
          >
            <p className="m-0 ms-2 text-warning">pending</p>

            <p className="m-0 ms-5 text-gray-600">7:00 am</p>
          </div>
        </div>
      </div>
      <div
        className="card shadow-sm p-5 mb-5 d-flex flex-column"
        // key={index}
      >
        <div className="d-flex flex-row">
          <p className="d-flex flex-row ">
            Invoice Number :{" "}
            <p
              style={{
                // fontSize: "15px",
                // fontWeight: "bold",
                borderRadius: "5px",
              }}
              className={`m-0 badge ms-2`}
            >
              INV00019
            </p>
          </p>
        </div>
        <div className="d-flex flex-row">
          <p className="d-flex flex-row m-0 text-nowrap">Status:</p>
          <div
            className="d-flex flex-row justify-content-between "
            style={{
              width: "1000px",
            }}
          >
            <p className="m-0 ms-2 text-success">paid</p>

            <p className="m-0 ms-5 text-gray-600">2 days ago</p>
          </div>
        </div>
      </div>
      <div
        className="card shadow-sm p-5 mb-5 d-flex flex-column"
        // key={index}
      >
        <div className="d-flex flex-row">
          <p className="d-flex flex-row ">
            Invoice Number :{" "}
            <p
              style={{
                // fontSize: "15px",
                // fontWeight: "bold",
                borderRadius: "5px",
              }}
              className={`m-0 badge ms-2`}
            >
              INV00018
            </p>
          </p>
        </div>
        <div className="d-flex flex-row">
          <p className="d-flex flex-row m-0 text-nowrap">Status:</p>
          <div
            className="d-flex flex-row justify-content-between "
            style={{
              width: "1000px",
            }}
          >
            <p className="m-0 ms-2 text-warning">cancelled</p>

            <p className="m-0 ms-5 text-gray-600">20 Sep 2024</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default VendorDetails;
