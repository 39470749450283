import "react-datepicker/dist/react-datepicker.css";
import "react-international-phone/style.css";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import usd from "../../../_metronic/assets/Icons/USD image.png";
import zar from "../../../_metronic/assets/Icons/ZAR image.webp";
import copy from "../../../_metronic/assets/all-serviceIcons/copy-alt.svg";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Accounts } from "../../interface/Interface";
import { enhancedFetch } from "../../modules/auth/core/_requests";

interface Account {
  accountNumber: string;
  customerId: string;
  currency: string;
  accountType: string;
  subAccountExist: boolean;
  swiftCode: string;
}

const AccountDetails = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const [accounts, setAccounts] = useState<Accounts[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<Accounts>();
  const [remainingZAR, setRemZAR] = useState(0);
  const [remainingUSD, setRemUSD] = useState(0);

  const getAccounts = async () => {
    try {
      const url = `${API_URL}/transaction/getAccounts`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setRemZAR(
          1 -
            data.results?.filter((acc: any) => acc?.currency === "ZAR")?.length
        );
        setRemUSD(
          1 -
            data.results?.filter((acc: any) => acc?.currency === "USD")?.length
        );
        setAccounts(data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAccounts();
  }, []);

  const createAccount = async (acc: string) => {
    try {
      const url = `${API_URL}/${acc}`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        getAccounts();

        const accountDetails = Object.entries(data?.message?.accounts?.[0])
          .filter(([key]) => key in accountKeyMap) // Only include mapped keys
          .map(
            ([key, value]) =>
              `<strong>${
                accountKeyMap[key as keyof Account]
              }:</strong> ${String(value)}`
          )
          .join("<br>");

        Swal.fire({
          icon: "success",
          title: "Account added successfully",
          text: "Account Details",
          html: accountDetails,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCopyClick = (text: string, name: string) => {
    const result = name.replace(/([A-Z])/g, " $1").trim();

    const formattedName = result.charAt(0).toUpperCase() + result.slice(1);

    navigator.clipboard.writeText(text).then(() => {
      toast(`${formattedName} copied `);
    });
  };

  const handleAllCopy = (account: Accounts) => {
    const accountString = `IBAN/Acccount Number: ${account?.accountNumber},\nCurrency: ${account?.currency},\nSwift: ${account?.swiftCode},\nAccount Holder Name: ${account?.accountHolderName},\nAddress: ${account?.accountHolderAddress}`;
    navigator.clipboard.writeText(accountString).then(() => {
      toast(`Account details copied`);
    });
  };

  const accountKeyMap: { [key in keyof Account]?: string } = {
    accountNumber: "IBAN/Account Number",
    currency: "Currency",
    customerId: "Customer ID",
    swiftCode: "SWIFT/BIC",
  };

  const keyMap: Partial<Record<keyof Accounts, string>> = {
    accountNumber: "IBAN/Account Number",
    accountHolderName: "Account Holder Name",
    swiftCode: "SWIFT Code",
    accountHolderAddress: "Address",
    currency: "Currency",
  };

  const getStatusColor = (status: string) => {
    console.log("status  is ", status);
    switch (status) {
      case "Active":
        return "#8BC255";
      case "Inactive":
        return "#919190";
      case "Partial Access":
        return "#ffb042";
      case "Account Locked":
        return "#ff0000";
      default:
        return "#ffb042";
    }
  };
  return (
    <>
      {" "}
      <h4 className="mb-5">Account Details</h4>
      <div
        className={`d-flex ${
          window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
        }`}
      >
        <div className="card d-flex flex-column flex-grow-1">
          <div className=" d-flex mt-5 justify-content-end px-5">
            <button
              type="button"
              className="btn btn-primary"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
            >
              New currency A/c
            </button>
            <div
              className="menu menu-sub menu-sub-dropdown w-400px"
              data-kt-menu="true"
              id="filter_dropdown"
            >
              <div className="px-7 py-5">
                <div className="fs-5 text-gray-900">
                  Choose your account currency
                </div>
              </div>
              <div className="separator border-gray-200"></div>

              <div
                className="d-flex flex-row justify-content-between p-5 bg-hover-secondary"
                onClick={() => {
                  if (remainingZAR) {
                    Swal.fire({
                      icon: "question",
                      text: "Would you like to open ZAR account?",
                    }).then((res) => {
                      if (res.isConfirmed) {
                        createAccount("kycCreateSmeBankAccount");
                      }
                    });
                  }
                }}
              >
                <div className="d-flex flex-row">
                  <div className="d-flex flex-row me-5 align-items-center">
                    <img
                      src={zar}
                      alt=""
                      style={{
                        height: "30px",
                        width: "50px",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <div>
                    <p className="m-0 fs-7">ZAR</p>
                    <p className="m-0 fs-7">South African Rand</p>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <p className=" badge badge-light rounded">
                    {`${remainingZAR} out of 1 remaining`}
                  </p>
                </div>
              </div>
              <div className="separator border-gray-200"></div>
              <div
                className="d-flex flex-row justify-content-between p-5 bg-hover-secondary"
                onClick={() => {
                  if (remainingUSD) {
                    Swal.fire({
                      icon: "question",
                      text: "Would you like to open USD account?",
                    }).then((res) => {
                      if (res.isConfirmed) {
                        createAccount("openUsdAccount");
                      }
                    });
                  }
                }}
              >
                <div className="d-flex flex-row">
                  <div className="d-flex flex-row me-5 align-items-center">
                    <img
                      src={usd}
                      alt=""
                      style={{
                        height: "30px",
                        width: "50px",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <div>
                    <p className="m-0 fs-7">USD</p>
                    <p className="m-0 fs-7">US Dollar</p>
                  </div>
                </div>
                <div className="d-flex">
                  <p className=" badge badge-light rounded">
                    {`${remainingUSD} out of 1 remaining`}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ overflowX: "scroll" }}
            className="card shadow-sm p-5 px-8 m-5"
          >
            <table
              className="table align-middle table-row-dashed fs-6 gy-5"
              id="kt_customers_table"
            >
              <thead>
                <tr className="text-start text-gray-400 fw-bold fs-7 gs-0">
                  <th className="min-w-100px">Currency</th>
                  <th className="min-w-125px">IBAN/Account Number</th>
                  <th className="min-w-125px">Created Date</th>
                  <th className="min-w-125px">Created By</th>
                  <th className="min-w-125px">Status</th>
                  <th className="min-w-125px">Action</th>
                  {/* <th className="min-w-125px">Session ID</th> */}
                </tr>
              </thead>
              <tbody className="fw-semibold text-gray-600">
                {accounts?.length === 0 ? (
                  <tr>
                    <td colSpan={7} className="text-center">
                      No data found.
                    </td>
                  </tr>
                ) : (
                  accounts?.map((acc, index) => (
                    <tr>
                      <td>{acc?.currency}</td>
                      <td>{acc?.accountNumber}</td>
                      <td>
                        {new Date(acc?.createdDate).toLocaleDateString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          }
                        )}
                      </td>
                      <td>Self</td>
                      <td style={{ color: getStatusColor(acc.accStatus) }}>
                        {acc?.accStatus}
                      </td>
                      <td>
                        <i
                          className="bi bi-eye cursor-pointer text-hover-primary"
                          onClick={() => setSelectedAccount(acc)}
                          id="kt_acc_modal_toggle"
                        ></i>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        id="kt_acc_modal"
        className="bg-body"
        data-kt-drawer="true"
        data-kt-drawer-name="modal"
        data-kt-drawer-activate="true"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'449px', 'md': '455px'}"
        data-kt-drawer-direction="end"
        data-kt-drawer-toggle="#kt_acc_modal_toggle"
        data-kt-drawer-close="#kt_modal_acc_close"
      >
        <div className="card shadow-none rounded-0 w-100 p-5">
          <div
            className="card-header d-flex align-items-center justify-content-start gap-5 p-5"
            id="kt_help_header"
          >
            <div
              className="btn btn-icon btn-light "
              id="kt_modal_acc_close"
              style={{ height: "24px", width: "24px" }}
            >
              <i className="bi bi-chevron-left"></i>
            </div>
            <h5 className="card-title fw-bold text-dark p-0">
              Account Details
            </h5>
          </div>
          <div className="card-body p-0 mx-5 d-flex flex-column justify-content-between">
            <div>
              {selectedAccount &&
                Object.entries(selectedAccount)
                  .filter(([key]) => keyMap[key as keyof Accounts])
                  .map(([key, value]) => (
                    <React.Fragment key={key}>
                      <div className="d-flex card p-5 d-flex flex-row justify-content-between">
                        <div>
                          <p className="m-0">{keyMap[key as keyof Accounts]}</p>
                          <p className="m-0 fw-bold">{String(value)}</p>{" "}
                        </div>
                        <div className="d-flex">
                          <img
                            src={copy}
                            alt="copy"
                            width={25}
                            height={40}
                            className="cursor-pointer text-hover-primary"
                            onClick={() => handleCopyClick(String(value), key)}
                          />
                        </div>
                      </div>
                      <hr className="m-0" />
                    </React.Fragment>
                  ))}
            </div>
            <div
              className="d-flex justify-content-center mt-5"
              style={{
                alignSelf: "self-end",
              }}
            >
              <button
                className="btn rounded"
                style={{
                  width: "380px",
                }}
                onClick={() =>
                  selectedAccount && handleAllCopy(selectedAccount)
                }
              >
                Copy Account Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AccountDetails;
