/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Modal } from "react-bootstrap";
import { defaultCreateAppData, ICreateAppData } from "./AppModel";
import { StepperModal } from "./StepperComponent";
import { StepperComponent } from "../../../../_metronic/assets/ts/components";
import { SteppperModal2 } from "./StepperModal2";
import { SteppperModal3 } from "./StepperModal3";
import { SteppperModal4 } from "./StepperModal4";
import { KTIcon } from "../../../../_metronic/helpers";
import { sameBank } from "./Data";
import { diffBank } from "./Data";
import { international } from "./Data";
import { wallet } from "./Data";
import { getCallingCode, GetCallingCodeTypes } from "country-data-codes";
import { ApiReset, SteppperModal1 } from "./StepperModal1";
import { countries } from "./Data";
import { ApiPayee } from "../../PayeeManagement/PayeeManagement";
import { PhoneNumberUtil } from "google-libphonenumber";
import { parsePhoneNumberFromString, AsYouType } from "libphonenumber-js";
import { enhancedFetch } from "../../../modules/auth/core/_requests";

import { ApiTrfMon } from "../../TransferMoney/Table";
import Swal from "sweetalert2";

const phoneUtil = PhoneNumberUtil.getInstance();

export const isPhoneValid = (phone: string) => {
  console.log(phone);

  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput("+" + phone));
  } catch (error) {
    console.log(error);
    return false;
  }
};

interface Country {
  name: string;
  dial_code: string;
  code: string;
}

export interface PayeeInData {
  currency: string;
  payeeAccountNumber: string;
  payeeMobile: string;
  payeeFirstName: string;
  payeeLastName: string;
  businessName: string;
}

type Props = {
  show: boolean;
  handleClose: () => void;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

export const CreateAppModal = ({ show, handleClose }: Props) => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const userDetails = sessionStorage.getItem("userDetails");
  const userDet = userDetails ? JSON.parse(userDetails) : null;

  const [button, setButton] = useState("Ok");

  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [data, setData] = useState<ICreateAppData>(defaultCreateAppData);
  const [hasError, setHasError] = useState(false);
  const [phone, setPhone] = useState("");
  const [payeeData, setPayeeData] = useState<PayeeInData[] | null>([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [authority, setAuthority] = useState(0);

  const findCountryByISD = (isdCode: string): string => {
    const foundCountry = countries.find((country) => country.Dial === isdCode);
    return foundCountry
      ? foundCountry["ISO3166-1-Alpha-3"] || "NA"
      : "Country not found";
  };

  useEffect(() => {
    if (userDet?.userDetails?.authority?.includes("2")) {
      setButton("Initiate");
    }
    if (
      userDet?.userDetails?.authority?.length === 1 &&
      userDet?.userDetails?.authority?.includes("3")
    ) {
      setAuthority(3);
    }
  }, []);

  // const dialingCode: GetCallingCodeTypes = getCallingCode(data.country.country);

  let pin = data.pin.pin;

  diffBank.payeeCategory = data.diffAccType.diffaccType || "";
  diffBank.businessName = data.diffCompanyName.diffcompanyName || "";
  diffBank.payeeFirstName = data.diffFirstName.difffirstname || "";
  diffBank.payeeLastName = data.diffLastName.difflastname || "";
  diffBank.payeeSubType = "INTER";
  diffBank.payeeAlias = data.diffnickname.diffnickname || "";
  diffBank.payeeAccountNumber = data.diffaccNumber.diffAccNumber || "";
  diffBank.payeeBank = data.bank.bank || "";
  diffBank.payeeBic = data.BIC.BIC || "";
  diffBank.payeeType = "BANK";
  diffBank.currency = data?.diffcurrency?.diffCurrency || "";
  // diffBank.payeeBranchCode = data.branchCode.branchCode || "";
  diffBank.payeeLimit = data.diffPayeeLimit.diffPayeeLimit || "";

  international.payeeCategory = "RETAIL";
  international.payeeFirstName = data?.intFirstName.intfirstname || "";
  international.payeeLastName = data?.intLastName.intlastname || "";
  international.payeeAlias = data?.intNickName.intNickName || "";
  international.payeeDob = data.intDOB.intDob || "";
  international.isdCode = data.intIsdCode.intIsdCode || "";
  international.mobileNumber = data.intPhone.intPhone || "";
  international.payeeCountry = data.intCountry.intCountry || "";
  international.payeeAccountNumber =
    data.intFundType.intfundType === 2
      ? `${data.intIsdCode.intIsdCode}-${data.intPhone.intPhone}`
      : data.intAccNumber.intAccNumber || "";
  international.currency = data.IntCurrency.IntCurrency || "";
  international.payeeBank = data.intBank.intBank || "";
  international.payeeLimit = data.intPayeeLimit.intPayeeLimit || "";
  international.payeeBic = data.intBIC.intBic || "";
  international.businessName = data.intCompanyName.intcompanyName || "";
  international.fundType =
    data.intFundType.intfundType === 1 ? "BANK" : "WALLET";
  international.idType = data.intIdType.intIdType || "";
  international.idNumber = data.intIdNum.intIdNum || "";
  international.address = data.intAddress.intAddress || "";
  international.city = data.intCity.intCity || "";
  international.state = data.intState.intState || "";
  international.payeeType = "REMITTANCE";

  let addPayeeData: any;

  if (data.appBasic.appType === "Same Bank") {
    addPayeeData = sameBank;
  } else if (data.appBasic.appType === "Different Bank") {
    addPayeeData = diffBank;
  } else if (data.appBasic.appType === "International") {
    addPayeeData = international;
  } else if (data.appBasic.appType === "Wallet") {
    addPayeeData = wallet;
  }

  const createPayee = async () => {
    try {
      const url = `${API_URL}/payee/addPayee`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        ...addPayeeData,
        ...(button === "Initiate" && {
          approverGuid: data.approverGuid.approverGuid,
          approverName: data.approverName.approverName,
          initiatedTxn: true,
        }),
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let res = await response.json();

      if (res?.status.statusCode === 0) {
        ApiPayee.api();
        if (button === "Initiate") {
          handleClose();
          resetData1();
          resetData2();
          resetData3();
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            html: "Initiation successful <br> Pending for approval",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        }
      }
      if (res?.status.statusCode === 1) {
        if (data.appBasic.appType === "Same Bank") {
          resetData1();
          resetData2();
          stepper.current?.goto(2);
        } else {
          stepper.current?.goPrev();
        }

        Swal.fire({
          icon: "warning",
          title: "Confirmation",
          text: res.status.messageDescription,
          allowEscapeKey: true,
          allowEnterKey: true,
        });
        // alert(data.status.messageDescription);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validatePin = async () => {
    try {
      const url = `${API_URL}/payee/validatePin`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        pin: pin,
        payeeType: addPayeeData?.payeeType,
        payeeAlias: addPayeeData?.payeeAlias,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      ApiPayee.api();
      ApiTrfMon.api();
      if (data.status.statusCode === 1) {
        setErrorMsg(data.status.messageDescription);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const updateData = (fieldsToUpdate: Partial<ICreateAppData>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const checkAppBasic = (): boolean => {
    console.log(data.accType.acctype);
    if (!data.appBasic.appType) {
      return false;
    }

    return true;
  };

  const checkDetails = (): boolean => {
    console.log(data.accType.acctype);

    if (!data.accType.acctype) {
      return false;
    }
    if (!data.firstname.firstname) {
      return false;
    }
    return true;
  };

  const checkDetails_1 = (): boolean => {
    if (!data.companyName.companyName && data.accType.acctype === "BUSINESS") {
      return false;
    }
    if (!data.firstname.firstname) {
      return false;
    }
    if (!data.phone.phone) {
      return false;
    }

    if (!isPhoneValid(data.country.country + " " + data.phone.phone)) {
      return false;
    }
    if (!data.currency.currency) {
      return false;
    }
    if (!data.accNumber.accNumber) {
      return false;
    }
    // if (data.accNumber.accNumber.length < 10) {
    //   return false;
    // }
    if (!data.nickname.nickname) {
      return false;
    }
    if (!data.payeelimit.payeelimit || +data.payeelimit.payeelimit === 0) {
      return false;
    }

    return true;
  };

  const checkDetails2 = (): boolean => {
    if (!data.diffAccType.diffaccType) {
      return false;
    }
    return true;
  };

  const checkDetails2_1 = (): boolean => {
    if (!data.diffFirstName.difffirstname) {
      return false;
    }
    if (!data.diffLastName.difflastname) {
      return false;
    }
    if (!data.diffnickname.diffnickname) {
      return false;
    }
    if (!data.bank.bank) {
      return false;
    }
    if (!data.diffaccNumber.diffAccNumber) {
      return false;
    }
    if (data.diffaccNumber.diffAccNumber.length < 10) {
      return false;
    }
    if (!data.BIC.BIC) {
      return false;
    }
    // if (!data.branchCode.branchCode) {
    //   return false;
    // }
    if (!data.diffcurrency.diffCurrency) {
      return false;
    }
    if (
      !data.diffPayeeLimit.diffPayeeLimit ||
      +data.diffPayeeLimit.diffPayeeLimit === 0
    ) {
      return false;
    }
    return true;
  };

  const checkDetails3 = (): boolean => {
    if (!data.intAccType.intaccType) {
      return false;
    }
    return true;
  };

  const checkDetails3_1 = (): boolean => {
    if (!data.intFirstName.intfirstname) {
      return false;
    }
    if (!data.intLastName.intlastname) {
      return false;
    }
    if (!data.intNickName.intNickName) {
      return false;
    }
    if (
      data.accType.acctype === "BUSINESS" &&
      !data.intCompanyName.intcompanyName
    ) {
      return false;
    }
    if (!data.intIsdCode.intIsdCode) {
      return false;
    }
    if (!data.intPhone.intPhone) {
      return false;
    }
    if (data.intPhone.intPhone.length < 9) {
      return false;
    }
    // if (!isPhoneValid(
    //   data.intIsdCode.intIsdCode + " " + data.intPhone.intPhone
    // )) {
    //   return false;
    // }
    if (!data.intIdType.intIdType) {
      return false;
    }
    if (!data.intIdNum.intIdNum) {
      return false;
    }
    if (!data.intAddress.intAddress) {
      return false;
    }
    if (!data.intCity.intCity) {
      return false;
    }
    if (!data.intState.intState) {
      return false;
    }
    if (!data.intCountry.intCountry) {
      return false;
    }
    if (!data.intDOB.intDob) {
      return false;
    }
    if (data.intAccType.intaccType === "RETAIL" && data.intDOB.intDob) {
      const selectedDate = new Date(data.intDOB.intDob);
      const maxDate = new Date(
        new Date().getFullYear() - 15,
        new Date().getMonth(),
        new Date().getDate()
      );
      console.log(selectedDate <= maxDate);

      return selectedDate <= maxDate;
    }

    if (!data.intFundType.intfundType) {
      return false;
    }
    if (data.intFundType.intfundType === 1 && !data.intBank.intBank) {
      return false;
    }
    if (data.intFundType.intfundType === 1 && !data.intAccNumber.intAccNumber) {
      return false;
    }
    if (
      data.intFundType.intfundType === 1 &&
      data.intAccNumber.intAccNumber.length < 10
    ) {
      return false;
    }
    if (data.intFundType.intfundType === 1 && !data.intBIC.intBic) {
      return false;
    }
    if (data.intFundType.intfundType === 1 && !data.IntCurrency.IntCurrency) {
      return false;
    }
    if (
      data.intFundType.intfundType === 2 &&
      data.intAccNumber.intAccNumber.length < 12
    ) {
      return false;
    }
    // if (data.intFundType.intfundType === 2) {
    //   return !isPhoneValid(
    //     data.intIsdCode.intIsdCode + " " + data.intPhone.intPhone
    //   );
    // }
    if (
      !data.intPayeeLimit.intPayeeLimit ||
      +data.intPayeeLimit.intPayeeLimit === 0
    ) {
      return false;
    }
    return true;
  };
  const checkDetails4 = (): boolean => {
    if (!data.walletAccType.walletaccType) {
      return false;
    }
    if (!data.walletPhone.walletPhone) {
      return false;
    }
    return true;
  };
  const checkDetails4_1 = (): boolean => {
    if (!data.walletPhone.walletPhone) {
      return false;
    }
    if (
      !data.walletCompanyName.walletcompanyName &&
      data.walletAccType.walletaccType === "BUSINESS"
    ) {
      return false;
    }
    if (!data.walletFirstName.walletfirstname) {
      return false;
    }
    // if (!data.walletLastName.walletlastname) {
    //   return false;
    // }
    if (!data.walletNickName.walletNickName) {
      return false;
    }
    if (!data.walletCurrency.walletCurrency) {
      return false;
    }
    if (
      !data.walletPayeeLimit.walletPayeeLimit ||
      +data.walletPayeeLimit.walletPayeeLimit === 0
    ) {
      return false;
    }
    return true;
  };

  const resetData1 = () => {
    setData((prevData) => ({
      ...prevData,
      accType: { acctype: "" },
      // intAccType: { intaccType: "" },
      diffAccType: { diffaccType: "" },
      phone: { phone: "" },
      cusId: { cusId: "" },
      firstname: { firstname: "" },
      approverName: { approverName: "" },
      approverGuid: { approverGuid: "" },
    }));
    setPayeeData([]);
    setPhone("");
  };

  const resetData2 = () => {
    setData((prevData) => ({
      ...prevData,
      // accNumber: { accNumber: "" },
      companyName: { companyName: "" },
      // lastname: { lastname: "" },
      nickname: { nickname: "" },
      // currency: { currency: "" },
      payeelimit: { payeelimit: "" },
      name: { name: "" },
      diffnickname: { diffnickname: "" },
      bank: { bank: "" },
      diffaccNumber: { diffAccNumber: "" },
      confirmAccNumber: { confirmdiffAccNumber: "" },
      branchCode: { branchCode: "" },
      BIC: { BIC: "" },
      diffcurrency: { diffCurrency: "" },
      diffCompanyName: { diffcompanyName: "" },
      diffFirstName: { difffirstname: "" },
      diffLastName: { difflastname: "" },
      diffPayeeLimit: { diffPayeeLimit: "" },
      country: { country: "IN" },
      intIsdCode: { intIsdCode: "" },
      intPhone: { intPhone: "" },
      intName: { intName: "" },
      intCompanyName: { intcompanyName: "" },
      intFirstName: { intfirstname: "" },
      intLastName: { intlastname: "" },
      intDOB: { intDob: "" },
      intIdType: { intIdType: "" },
      intNickName: { intNickName: "" },
      intAccNumber: { intAccNumber: "" },
      intIdNum: { intIdNum: "" },
      intBIC: { intBic: "" },
      IntCurrency: { IntCurrency: "" },
      intCountry: { intCountry: "" },
      intFundType: { intfundType: 0 },
      intBank: { intBank: "" },
      intAddress: { intAddress: "" },
      intCity: { intCity: "" },
      intState: { intState: "" },
      intPayeeLimit: { intPayeeLimit: "" },
      walletPhone: { walletPhone: "" },
      walletCountry: { walletCountry: "" },
      walletCompanyName: { walletcompanyName: "" },
      walletFirstName: { walletfirstname: "" },
      walletLastName: { walletlastname: "" },
      walletNickName: { walletNickName: "" },
      walletCurrency: { walletCurrency: "" },
      walletPayeeLimit: { walletPayeeLimit: "" },
    }));
  };

  const resetData3 = () => {
    setData((prevData) => ({
      ...prevData,
      pin: { pin: "" },
    }));
  };

  const checkPassword = (): boolean => {
    if (!data.pin.pin) {
      return false;
    }

    return true;
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }
    if (stepper.current.getCurrentStepIndex() === 2) {
      resetData1();
      ApiReset.api();
      stepper.current.goPrev();
    }
    if (stepper.current.getCurrentStepIndex() === 3) {
      resetData2();
      stepper.current.goPrev();
    }
    if (stepper.current.getCurrentStepIndex() === 4) {
      resetData3();
    }
  };

  const nextStep = () => {
    setHasError(false);
    if (!stepper.current) {
      return;
    }

    if (stepper.current.getCurrentStepIndex() === 1) {
      if (!checkAppBasic()) {
        setHasError(true);
        return;
      }
    }

    if (button === "Initiate") {
      if (stepper.current.getCurrentStepIndex() === 2) {
        if (!data.approverName.approverName) {
          setHasError(true);
          return false;
        }
      }
    }
    if (
      stepper.current.getCurrentStepIndex() === 2 &&
      data.appBasic.appType === "Same Bank"
    ) {
      if (!checkDetails()) {
        setHasError(true);
        return;
      }
    } else if (
      stepper.current.getCurrentStepIndex() === 2 &&
      data.appBasic.appType === "Different Bank"
    ) {
      if (!checkDetails2()) {
        setHasError(true);
        return;
      }
    } else if (
      stepper.current.getCurrentStepIndex() === 2 &&
      data.appBasic.appType === "International"
    ) {
      if (!checkDetails3()) {
        setHasError(true);
        return;
      }
    } else if (
      stepper.current.getCurrentStepIndex() === 2 &&
      data.appBasic.appType === "Wallet"
    ) {
      if (!checkDetails4()) {
        setHasError(true);
        return;
      }
    }

    if (
      stepper.current.getCurrentStepIndex() === 3 &&
      data.appBasic.appType === "Same Bank"
    ) {
      if (!checkDetails_1()) {
        setHasError(true);
        return;
      }
    } else if (
      stepper.current.getCurrentStepIndex() === 3 &&
      data.appBasic.appType === "Different Bank"
    ) {
      if (!checkDetails2_1()) {
        setHasError(true);
        return;
      }
    } else if (
      stepper.current.getCurrentStepIndex() === 3 &&
      data.appBasic.appType === "International"
    ) {
      if (!checkDetails3_1()) {
        setHasError(true);
        return;
      }
    } else if (
      stepper.current.getCurrentStepIndex() === 3 &&
      data.appBasic.appType === "Wallet"
    ) {
      if (!checkDetails4_1()) {
        setHasError(true);
        return;
      }
    }

    if (stepper.current.getCurrentStepIndex() === 4) {
      if (!checkPassword()) {
        setHasError(true);
        return;
      }
    }

    if (stepper.current?.getCurrentStepIndex() === 3 && addPayeeData) {
      createPayee();
    }
    if (
      stepper.current?.getCurrentStepIndex() === 4 &&
      data.pin.pin.length === 4
    ) {
      validatePin();
    }

    setHasError(false);
    stepper.current.goNext();
  };

  const submit = () => {
    if (button === "Initiate") {
      if (stepper.current?.getCurrentStepIndex() === 3 && addPayeeData) {
        createPayee();
      }
    } else {
      handleClose();
      resetData1();
      resetData2();
      resetData3();
    }
  };

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={() => {
        handleClose();
        updateData({
          appBasic: { appName: "", appType: "Same Bank" },
        });
        resetData1();
        resetData2();
        resetData3();
      }}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className="modal-header">
        <h3 className="fw-bold m-0">Add Payee</h3>
        {/* <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
          onClick={() => {
            handleClose();
            updateData({
              appBasic: { appName: "", appType: "Same Bank" },
            });
            resetData1();
            resetData2();
            resetData3();
          }}
        >
          <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
        </div> */}
      </div>

      <div
        className="modal-body py-lg-10 px-lg-10"
        style={{
          display: authority === 3 ? "flex" : "",
          minHeight: "300px",
          alignItems: authority === 3 ? "center" : "normal",
          justifyContent: authority === 3 ? "center" : "normal",
        }}
      >
        {authority === 3 ? (
          <div>
            <p className="fw-bold text-center text-danger">
              You don't have access to this feature.
            </p>

            <p className="fw-bold text-center text-danger">
              Please contact admin.
            </p>
          </div>
        ) : (
          <div
            ref={stepperRef}
            className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
            id="kt_modal_create_app_stepper"
          >
            <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
              <div className="stepper-nav ps-lg-10">
                <div
                  className="stepper-item current"
                  data-kt-stepper-element="nav"
                >
                  <div className="stepper-wrapper">
                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">1</span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title">
                        Choose type of payee account
                      </h3>
                    </div>
                  </div>
                  <div className="stepper-line h-40px"></div>
                </div>
                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-wrapper">
                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">2</span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title">Enter Payee details</h3>
                    </div>
                  </div>
                  <div className="stepper-line h-40px"></div>
                </div>
                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-wrapper">
                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">3</span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title">Enter the details</h3>
                    </div>
                  </div>
                  {button === "Initiate" ? null : (
                    <div className="stepper-line h-40px"></div>
                  )}
                </div>
                {button === "Initiate" ? null : (
                  <>
                    {" "}
                    <div className="stepper-item" data-kt-stepper-element="nav">
                      <div className="stepper-wrapper">
                        <div className="stepper-icon w-40px h-40px">
                          <i className="stepper-check fas fa-check"></i>
                          <span className="stepper-number">4</span>
                        </div>
                        <div className="stepper-label">
                          <h3 className="stepper-title">Enter your PIN</h3>
                        </div>
                      </div>
                      <div className="stepper-line h-40px"></div>
                    </div>
                    <div className="stepper-item" data-kt-stepper-element="nav">
                      <div className="stepper-wrapper">
                        <div className="stepper-icon w-40px h-40px">
                          <i className="stepper-check fas fa-check"></i>
                          <span className="stepper-number">5</span>
                        </div>
                        <div className="stepper-label">
                          <h3 className="stepper-title">Confirmation</h3>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            {/*begin::Content */}
            <div className="flex-row-fluid py-lg-5 px-lg-15">
              <form noValidate id="kt_modal_create_app_form ">
                <StepperModal
                  data={data}
                  phone={phone}
                  setPhone={setPhone}
                  payeeData={payeeData}
                  setPayeeData={setPayeeData}
                  updateData={updateData}
                  hasError={hasError}
                />
                <SteppperModal1
                  data={data}
                  phone={phone}
                  setPhone={setPhone}
                  payeeData={payeeData}
                  setPayeeData={setPayeeData}
                  updateData={updateData}
                  hasError={hasError}
                />
                <SteppperModal2
                  data={data}
                  phone={phone}
                  setPhone={setPhone}
                  payeeData={payeeData}
                  setPayeeData={setPayeeData}
                  updateData={updateData}
                  hasError={hasError}
                />
                {button === "Initiate" ? null : (
                  <>
                    <SteppperModal3
                      data={data}
                      phone={phone}
                      setPhone={setPhone}
                      payeeData={payeeData}
                      setPayeeData={setPayeeData}
                      updateData={updateData}
                      hasError={hasError}
                    />
                    <SteppperModal4 error={errorMsg} />
                  </>
                )}

                <div className="d-flex flex-stack pt-10">
                  <div className="me-2">
                    {stepper.current?.getCurrentStepIndex() !== 4 &&
                      stepper.current?.getCurrentStepIndex() !== 5 && (
                        <button
                          type="button"
                          className="btn btn-lg btn-light-primary me-3"
                          data-kt-stepper-action="previous"
                          onClick={prevStep}
                        >
                          <KTIcon iconName="arrow-left" className="fs-3 me-1" />{" "}
                          Previous
                        </button>
                      )}
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-lg btn"
                      data-kt-stepper-action="submit"
                      onClick={submit}
                    >
                      {button}{" "}
                      <KTIcon
                        iconName="arrow-right"
                        className="fs-3 ms-2 me-0"
                      />
                    </button>

                    <button
                      type="button"
                      className="btn btn-lg btn"
                      data-kt-stepper-action="next"
                      onClick={nextStep}
                    >
                      Next
                      <KTIcon
                        iconName="arrow-right"
                        className="fs-3 ms-1 me-0"
                      />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </Modal>,
    modalsRoot
  );
};
