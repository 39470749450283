import { useState, useEffect } from "react";
import { KTIcon } from "../../../../../_metronic/helpers";
import { enhancedFetch } from "../../../../modules/auth/core/_requests";

interface Data {
  isfavourite: boolean;
  customerGuid: string;
  customerName: string;
  companyName: string;
  countryType: string;
  isdCode: number;
  phoneNumber: string;
  emailId: string;
  address: string;
  vatNumber: string;
  notes: string;
  bankName: string;
  bankAccountNo: string;
  bic: string;
  type: number;
}

export function CustomerDetailsModal({ Data }: { Data: Data | null }) {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const [viewData, setViewData] = useState<Data | null>(null);

  const getItemDataa = async () => {
    try {
      const url = `${API_URL}/customer/load`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        customerGuid: Data?.customerGuid,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      setViewData(data.message);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getItemDataa();
  }, [Data]);

  return (
    <>
      <div
        id="kt_modal_customer"
        className="bg-body"
        data-kt-drawer="true"
        data-kt-drawer-name="modal"
        data-kt-drawer-activate="true"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'449px', 'md': '455px'}"
        data-kt-drawer-direction="end"
        data-kt-drawer-toggle="#kt_modal_toggle_customer"
        data-kt-drawer-close="#kt_modal_close_customer"
      >
        {/* begin::Card */}
        <div className="card shadow-none rounded-0 w-100 p-5">
          {/* begin::Header */}
          <div
            className="card-header d-flex align-items-center justify-content-start gap-5 p-5"
            id="kt_help_header"
          >
            <div
              className="btn btn-icon btn-light "
              id="kt_modal_close_customer"
              style={{ height: "24px", width: "24px" }}
            >
              <i className="bi bi-chevron-left"></i>
            </div>
            <h5 className="card-title fw-bold text-gray-600 p-0">Details</h5>
            <div className="card-toolbar"></div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}

          <div className="card shadow p-5">
            {/* <h4>Item Details</h4> */}
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Customer Name</p>
              <p>{viewData?.customerName} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Company Name</p>
              <p>{viewData?.companyName} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Category</p>
              <p>
                {viewData?.type === 1
                  ? "Customer"
                  : viewData?.type === 2
                  ? "Vendor"
                  : "Both"}
              </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Country Type</p>
              <p>{viewData?.countryType} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Isd Code</p>
              <p>+{viewData?.isdCode} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Phone Number</p>
              <p>{viewData?.phoneNumber} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Email Id</p>
              <p>{viewData?.emailId} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Bank Name</p>
              <p>{viewData?.bankName} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Bank Account Number</p>
              <p>{viewData?.bankAccountNo} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Address</p>
              <p>{viewData?.address} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">VAT Number</p>
              <p>{viewData?.vatNumber} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">BIC</p>
              <p>{viewData?.bic} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Notes</p>
              <p>{viewData?.notes} </p>
            </div>
          </div>

          {/* end::Body */}
        </div>
        {/* end::Card */}
      </div>
    </>
  );
}
