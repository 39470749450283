import React, { useRef, useEffect, useState } from "react";
import { KTSVG } from "../../../_metronic/helpers";
import deleteIcon from "../../../../_metronic/assets/Icons/Group.png";
import { Modal } from "bootstrap"; // Import the Modal module from Bootstrap
import PinInput from "react-pin-input";
import { Formik, Form, Field, ErrorMessage, FormikProps } from "formik";
import * as Yup from "yup";
import { transferData } from "./TransferData";
import Swal from "sweetalert2";
import { apiCall } from "./DashboardWrapper";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";

export function TransferAuthentication() {
  const modalRef = useRef<HTMLDivElement | null>(null);

  const [password, setPassword] = useState("");

  transferData.transactionPIN = password;

  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const API_URL = process.env.REACT_APP_WEB_API_URL;

  let ele = useRef<PinInput | null>(null);

  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [pin, setPin] = useState("");
  const [show, setShow] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRefs.current[activeInput]?.focus();
  }, [activeInput]);

  const handleChange = (value: string, index: number): string => {
    // Only allow positive numbers
    if (/^\d*$/.test(value) && pin.length < 4) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setPin(pin + value);

      if (value && index < otp.length - 1) {
        setActiveInput(index + 1);
      }
      return pin + value;
    }
    return pin;
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      }
    }
    return pin;
  };

  const handleCloseModal = () => {
    ele.current?.clear();
    setError("");
    setPassword("");
    setPin("");
    setOtp(["", "", "", ""]);
    setActiveInput(0);
    const modalElement = modalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  const handleTransferConfirmation = () => {
    const modalElement = document.getElementById("transfer-authentication");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  useEffect(() => {
    if (modalRef.current) {
      const modal = new Modal(modalRef.current);

      modalRef.current.addEventListener("shown.bs.modal", () => {
        ele?.current?.focus();
        inputRefs.current[activeInput]?.focus();
      });

      modalRef.current.addEventListener("hidden.bs.modal", handleCloseModal);
      return () => {
        modalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  const AddMoney = async () => {
    try {
      const url = `${API_URL}/subAccount/fundTransfer`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const filteredTransferData = Object.fromEntries(
        Object.entries(transferData).filter(
          ([key, value]) =>
            (typeof value === "boolean" && value === true) ||
            (typeof value !== "boolean" && value !== "")
        )
      );
      console.log(filteredTransferData);

      const body = JSON.stringify(filteredTransferData);

      // const body = JSON.stringify({
      //   ...transferData,
      // });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        handleCloseModal();
        if (transferData.initiatedTxn) {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            html: "Initiation successful <br> Pending for approval",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            text: "Transfer success",
            confirmButtonColor: "#007bff", // Set the button color to btn
            confirmButtonText: "OK",
            allowEscapeKey: true,
            allowEnterKey: true,
          }).then((result) => {
            if (result.isConfirmed) {
              apiCall.submit();
            }
          });
        }
      }
      if (data.status.statusCode === 1) {
        if (data.status.svcErrorMessage === "Invalid Pin") {
          setPassword("");
          setPin("");
          setOtp(["", "", "", ""]);
          setActiveInput(0);
          setError(data.status.svcErrorMessage);
        } else {
          handleCloseModal();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div
        className="modal"
        tabIndex={-1}
        id="transfer-authentication"
        ref={modalRef}
      >
        <div
          className="modal-dialog w-400px"
          style={{ margin: "0 auto", marginTop: "10%" }}
        >
          <div className="modal-content">
            <div className="modal-body p-5">
              <div className="d-flex flex-column justify-content-center align-items-center p-5">
                <div>
                  <h1 className="text-center">Enter Your PIN</h1>

                  <div className="d-flex flex-no-wrap justify-content-evenly">
                    <Formik
                      initialValues={{
                        securityCode: "", // Initialize with an empty string or any default value
                      }}
                      validationSchema={Yup.object({
                        securityCode: Yup.string()
                          .matches(/^\d{4}$/, "Security code must be 4 digits")
                          .required("Security code is required"),
                      })}
                      onSubmit={(values, actions) => {
                        AddMoney();
                        actions.resetForm();
                      }}
                    >
                      <Form>
                        <div className="mb-8">
                          <p className="text-nowrap mb-3 text-center">
                            Enter your PIN to confirm
                          </p>
                          <Field name="securityCode">
                            {({
                              field,
                              form,
                            }: {
                              field: any;
                              form: FormikProps<any>;
                            }) => (
                              <OTPInput
                                {...field}
                                value={otp.join("")}
                                onChange={(value: string) => {
                                  value
                                    .split("")
                                    .forEach((char, index) =>
                                      handleChange(char, index)
                                    );
                                }}
                                numInputs={4}
                                renderSeparator={<span>&nbsp;&nbsp;</span>}
                                renderInput={(props, index) => (
                                  <input
                                    {...props}
                                    value={otp[index]}
                                    ref={(el) =>
                                      (inputRefs.current[index] = el)
                                    }
                                    onChange={(e) => {
                                      const pin = handleChange(
                                        e.target.value,
                                        index
                                      );
                                      setError("");
                                      setPassword(pin);
                                      form.setFieldValue("securityCode", pin);
                                    }}
                                    onKeyDown={(e) => {
                                      setError("");
                                      const pin = handleKeyDown(e, index);
                                      setPassword(pin);
                                      form.setFieldValue("securityCode", pin);
                                    }}
                                    type={show ? "password" : "text"}
                                    autoFocus={true}
                                    disabled={index !== activeInput}
                                    onFocus={() => setActiveInput(index)}
                                    className="text-center rounded mt-3 border border-1 border-dark mx-auto"
                                    style={{ height: "50px", width: "50px" }}
                                  />
                                )}
                              />
                            )}
                          </Field>
                          <p
                            className="text-center text-decoration-underline cursor-pointer mb-0 mt-2"
                            onClick={() => setShow(!show)}
                          >
                            {show ? "Show PIN" : "Hide PIN"}
                          </p>
                          <div className="mt-3">
                            <ErrorMessage
                              name="securityCode"
                              component="div"
                              className="text-danger "
                            />
                            {error && (
                              <p className="text-center text-danger mt-3">
                                {error}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center justify-content-center">
                          <button
                            type="submit"
                            className="btn b rounded"
                            style={{
                              width: "230px",
                            }}
                          >
                            Confirm Payment
                          </button>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {<TransferConfirmation />} */}
    </>
  );
}
