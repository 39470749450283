import { Modal } from "bootstrap";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Accounts,
  BillerData,
  PayBill,
  PayBillData,
  PayBillResponse,
  TxnData,
} from "../../interface/Interface";
import electricityIcon from "../../../_metronic/assets/all-serviceIcons/electricity.svg";
import internetIcon from "../../../_metronic/assets/all-serviceIcons/wifi.svg";
import waterIcon from "../../../_metronic/assets/all-serviceIcons/droplet.svg";
import reachargeIcon from "../../../_metronic/assets/all-serviceIcons/recharge.svg";
// import { getAccountStmnt } from "../Transaction/TxnApi";
import Spinner from "react-bootstrap/Spinner";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import {
  BillerFavConsumer,
  MenuItem,
  Message,
  TransactionStatement,
  getAccounts,
  getBillDashboard,
  getBillPaymentMenu,
  getBillers,
  getBillersData,
  payBill,
  deleteCustomer,
} from "./PayBillApi";
import { EReceiptModal } from "./E-Receipt";
import Swal from "sweetalert2";
import PinInput from "react-pin-input";
import { BillPayReceipt } from "./BillPayReceipt";
import { getTransactionDetail } from "../Transaction/TxnApi";
import OTPInput from "react-otp-input";
import Authorize from "../../modules/auth/components/Authorize";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { User } from "../TransferMoney/TransferModal";
import { toast } from "react-toastify";

export const ApiData = {
  api: (): void => {},
};

const Paybill = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const baseCurrency = sessionStorage.getItem("currency");

  const userDetails = sessionStorage.getItem("userDetails");
  const userDet = userDetails ? JSON.parse(userDetails) : null;

  const [button, setButton] = useState("Pay Now");
  const [approvers, setApprovers] = useState<User[]>([]);

  const [approverName, setApproverName] = useState("");
  const [approverGuid, setApproverGuid] = useState("");
  const [apperrMsg, setAppErrMsg] = useState(false);
  const [authority, setAuthority] = useState(0);

  const BillDetailsModalRef = useRef<HTMLDivElement | null>(null);
  const SelectAccountModalRef = useRef<HTMLDivElement | null>(null);
  const selectBillerModalRef = useRef<HTMLDivElement | null>(null);
  const formikRef = useRef<FormikProps<any> | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);
  let ele = useRef<PinInput | null>(null);
  const [pinError, setPinError] = useState("");

  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const [data, setData] = useState<TransactionStatement[]>([]);
  const [selectedData, setSelectedData] = useState<TxnData>({
    amount: 0,
    txnId: "",
    txnStatus: 0,
    accountNumber: "",
    currency: "",
    txnTypeName: "",
    dateTime: "",
    txnType: 0,
    category: "",
    note: "",
    scheduled: "",
    hours: "",
    receipt: [],
    businessGuid: "",
    senderName: "",
    senderCustomerId: "",
    benefName: "",
    benefCustomerId: "",
  });
  const [billers, setBillers] = useState<string[]>([]);
  const [favourites, setFavourites] = useState<BillerFavConsumer[]>([]);
  const [txnPin, setTxnPin] = useState("");
  const [lowBalErr, setLowBalErr] = useState("");
  const [menuNames, setMenuNames] = useState<MenuItem[]>([]);
  const [billerType, setBillerType] = useState("");
  const [operator, setOperator] = useState("");
  const [accounts, setAccounts] = useState<Accounts[]>([]);
  const [selectedRadio, setSelectedRadio] = useState(accounts[0]?.balance);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [billerData, setBillerData] = useState<PayBillData>({
    userId: "",
    tenantId: "",
    customerName: "",
    billerNames: [],
    billerType: "",
    customerIdBiller: "",
    amountDue: 0,
    dueDate: "",
    billCurrency: "",
    billStatus: "",
  });
  const [response, setResponse] = useState<PayBillResponse>({
    Amount: "",
    "Service Charge": "",
    "Bank Account": "",
    "Biller Name": [],
    "Biller Type": "",
    "Customer Id": "",
    Date: "",
    Time: "",
    "Transaction Id": "",
    Type: "",
    Status: "",
  });
  const [showAll, setShowAll] = useState(false);

  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [pin, setPin] = useState("");
  const [show, setShow] = useState(true);
  const [isRotating, setIsRotating] = useState(true);
  const [rotationStates, setRotationStates] = useState(
    favourites.map(() => false)
  );

  const handleIconClick = (index: number) => {
    setRotationStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRefs.current[activeInput]?.focus();
  }, [activeInput]);

  const handleChange = (value: string, index: number): string => {
    // Only allow positive numbers
    if (/^\d*$/.test(value) && pin.length < 4) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setPin(pin + value);

      if (value && index < otp.length - 1) {
        setActiveInput(index + 1);
      }
      return pin + value;
    }
    return pin;
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      }
    }
    return pin;
  };

  const handleApprover = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAppErrMsg(false);
    setApproverGuid(e.target.value);
    const appName = approvers.find((app) => app.userGuid === e.target.value);
    if (appName) setApproverName(appName.name);
  };

  const handleViewClick = async (txnId: string) => {
    try {
      if (txnId) {
        const transaction = await getTransactionDetail(
          API_URL,
          token,
          accounts?.[0]?.accountNumber,
          txnId
        );

        setSelectedData(transaction);
      }
    } catch (error) {
      console.error("Failed to fetch transaction details:", error);
    }
  };

  const btnRef = useRef<HTMLButtonElement | null>(null);
  const onClick = () => {
    btnRef.current?.setAttribute("data-kt-indicator", "on");
    setTimeout(() => {
      // Activate indicator
      btnRef.current?.removeAttribute("data-kt-indicator");
    }, 1000);
  };

  const [openCardIndex, setOpenCardIndex] = useState<number | null>(null);

  const handleThreeDotsClick = (index: number) => {
    setOpenCardIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  // Function to handle click on the dropdown icon
  const handleDropdownClick = () => {
    setShowAll(!showAll);
  };

  const payBillData = {
    accountNumber: accounts[selectedIndex]?.accountNumber,
    accountType: accounts[selectedIndex]?.accountType,
    currency: accounts[selectedIndex]?.currency,
    amountDue: billerData?.amountDue,
    transactionPIN: txnPin,
    billerType: billerType ? billerType : billerData?.billerType,
    billerNames: operator ? [operator] : billerData?.billerNames,
    customerIdBiller: billerData?.customerIdBiller,
    connectionType: "POSTPAID",
    ...(button === "Initiate" && {
      approverGuid: approverGuid,
      beneficiaryName: operator ? operator : billerData?.billerNames[0],
      approverName: approverName,
      initiatedTxn: true,
    }),
  };

  const ApproverList = async (amount: string) => {
    try {
      const url = `${API_URL}/team/getApproverList`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        currency: baseCurrency,
        amount: amount,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setApprovers(data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userDet?.userDetails?.authority?.includes("2")) {
      const debounceTimeout = setTimeout(() => {
        if (+billerData?.amountDue) {
          ApproverList(billerData?.amountDue.toString());
        }
      }, 500);

      return () => clearTimeout(debounceTimeout);
    }
  }, [billerData?.amountDue]);

  useEffect(() => {
    if (userDet?.userDetails?.authority?.includes("2")) {
      ApproverList(userDet?.userDetails?.zarLimit);
      setButton("Initiate");
    }
    if (
      userDet?.userDetails?.authority?.length === 1 &&
      userDet?.userDetails?.authority?.includes("3")
    ) {
      setAuthority(3);
    }
  }, []);

  useEffect(() => {
    setSelectedRadio(accounts[0]?.balance);
  }, [accounts]);

  const handleDeleteClick = (fav: BillerFavConsumer) => {
    if (authority === 3) {
      Swal.fire({
        icon: "warning",

        html: `
    <p style="font-weight: bold; text-align: center; color: red;">
      You do not have access to this feature.
    </p>
 
    <p style="font-weight: bold; text-align: center; color: red;">
      Please contact admin.
    </p>
  `,
        allowEscapeKey: true,
        allowEnterKey: true,
      });
    } else {
      Swal.fire({
        icon: "warning",
        text: "Are you sure you want to delete favourite ?",
        showCancelButton: true,
        confirmButtonColor: "#007bff",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        allowEscapeKey: true,
        allowEnterKey: true,
      }).then((res) => {
        if (res.isConfirmed)
          deleteFavCustomer(fav?.billerType, fav?.billerName, fav?.customerId);
      });
    }
  };

  const handleSelectBiller = () => {
    const modalElement = document.getElementById("select_biller_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleSelectBillerClose = () => {
    formikRef.current?.resetForm();

    const modalElement = selectBillerModalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  useEffect(() => {
    if (selectBillerModalRef.current) {
      const modal = new Modal(selectBillerModalRef.current);
      selectBillerModalRef.current.addEventListener(
        "hidden.bs.modal",
        handleSelectBillerClose
      );
      return () => {
        selectBillerModalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleSelectBillerClose
        );
        modal.dispose();
      };
    }
  }, []);

  const handleBillDetails = () => {
    handleSelectBillerClose();
    const modalElement = document.getElementById("bill_details_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleBillDetailsClose = () => {
    const modalElement = BillDetailsModalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  useEffect(() => {
    if (BillDetailsModalRef.current) {
      const modal = new Modal(BillDetailsModalRef.current);
      BillDetailsModalRef.current.addEventListener(
        "hidden.bs.modal",
        handleBillDetailsClose
      );
      return () => {
        BillDetailsModalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleBillDetailsClose
        );
        modal.dispose();
      };
    }
  }, []);

  const handleSelectAccount = () => {
    fetchAccounts();
    handleBillDetailsClose();

    const modalElement = document.getElementById("select_account_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleSelectAccountClose = () => {
    const modalElement = SelectAccountModalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
    setSelectedRadio(0);
  };

  useEffect(() => {
    if (SelectAccountModalRef.current) {
      const modal = new Modal(SelectAccountModalRef.current);
      SelectAccountModalRef.current.addEventListener(
        "hidden.bs.modal",
        handleSelectAccountClose
      );
      return () => {
        SelectAccountModalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleSelectAccountClose
        );
        modal.dispose();
      };
    }
  }, []);

  async function fetchBillPayDashboard() {
    try {
      const data: Message = await getBillDashboard(API_URL, token);
      setData(data?.transactionStmnts);
      setFavourites(data?.billerFavConsumer);
    } catch (error) {
      console.log("Error fetching employee data:", error);
    }
  }

  ApiData.api = fetchBillPayDashboard;

  async function fetchBillPaymentMenu() {
    try {
      const data: MenuItem[] = await getBillPaymentMenu(API_URL, token);
      setMenuNames(data);
    } catch (error) {
      console.log("Error fetching employee data:", error);
    }
  }

  useEffect(() => {
    fetchBillPayDashboard();
    fetchBillPaymentMenu();
  }, []);

  async function fetchBillers(billerType: string) {
    try {
      const data: BillerData = await getBillers(API_URL, token, billerType);
      setBillers(data.billerNames);
    } catch (error) {
      console.log("Error fetching employee data:", error);
    }
  }

  async function fetchBillerData(
    billerType: string,
    billerNames: string[],
    customerId: string
  ) {
    try {
      const data: PayBillData = await getBillersData(
        API_URL,
        token,
        billerType,
        billerNames,
        customerId
      );

      setBillerData(data);
    } catch (error) {
      console.log("Error fetching employee data:", error);
    }
  }

  const refreshBiller = async (
    billerType: string,
    billerNames: string[],
    customerId: string
  ) => {
    try {
      const data: PayBillData = await getBillersData(
        API_URL,
        token,
        billerType,
        billerNames,
        customerId
      );

      setFavourites((prevFavourites) =>
        prevFavourites.map((fav) =>
          fav.customerId === data.customerIdBiller
            ? { ...fav, dueAmount: data.amountDue }
            : fav
        )
      );

      setRotationStates((prevStates) => {
        const newStates = [...prevStates];
        const index = favourites.findIndex(
          (item) => item.customerId === data.customerIdBiller
        );
        if (index !== -1) {
          newStates[index] = false;
        }
        return newStates;
      });
    } catch (error) {
      console.log("Error fetching biller data:", error);
    }
  };

  async function fetchAccounts() {
    try {
      const account: Accounts[] = await getAccounts(API_URL, token);
      setAccounts(account);
    } catch (error) {
      console.log("Error fetching account data:", error);
    }
  }

  async function deleteFavCustomer(
    billerType: string,
    billerNames: string,
    customerIdBiller: string
  ) {
    try {
      const data = await deleteCustomer(
        API_URL,
        token,
        billerType,
        billerNames,
        customerIdBiller
      );

      if (data?.status?.statusCode === 0) {
        const data: Message = await getBillDashboard(API_URL, token);
        setData(data?.transactionStmnts);
        setFavourites(data?.billerFavConsumer);
        setOpenCardIndex(null);
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Selected favourite biller has been deleted.",
          showCancelButton: false,
          confirmButtonColor: "#007bff",
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
      if (data?.status?.statusCode === 1) {
        Swal.fire({
          icon: "error",
          title: "Information",
          text: data?.status?.messageDescription,
          showCancelButton: false,
          confirmButtonColor: "#007bff",
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
    } catch (error) {
      console.log("Error deleting favourites data:", error);
    }
  }

  useEffect(() => {
    if (modalRef.current) {
      const modal = new Modal(modalRef.current);

      modalRef.current.addEventListener("shown.bs.modal", () => {
        ele?.current?.focus();
        inputRefs.current[activeInput]?.focus();
      });

      modalRef.current.addEventListener(
        "hidden.bs.modal",
        handlePaybillTxnPinClose
      );
      return () => {
        modalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handlePaybillTxnPinClose
        );
        modal.dispose();
      };
    }
  }, []);

  async function handleConfirmPayment(payBillData: PayBill) {
    try {
      const data = await payBill(API_URL, token, payBillData);
      if (data?.status?.statusCode === 0) {
        fetchBillPayDashboard();
        handlePaybillTxnPinClose();
        if (button === "Initiate") {
          setApproverGuid("");
          setApproverName("");
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            html: "Initiation successful <br> Pending for approval",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        } else {
          setResponse(data?.message?.receipt);
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            text: "Payment successful. Your bill has been paid.",
            showCancelButton: true,
            confirmButtonColor: "#007bff",
            confirmButtonText: "View E-Receipt",
            cancelButtonColor: "#9fa6b2",
            cancelButtonText: "Close",
            allowEscapeKey: true,
            allowEnterKey: true,
          }).then((result) => {
            if (result.isConfirmed) {
              handleEReceipt();
            }
          });
        }
      }
      if (data?.status?.statusCode === 1) {
        setOtp(["", "", "", ""]);
        setPin("");
        setActiveInput(0);
        if (data?.status?.messageDescription === "Invalid Pin")
          setPinError(data?.status?.messageDescription);
        else {
          toast.error(`${data?.status?.messageDescription}`);
          handlePaybillTxnPinClose();
        }
      }
    } catch (error) {
      console.log("Error fetching employee data:", error);
    }
  }

  const handleEReceipt = () => {
    setPinError("");
    const modalElement = document.getElementById("e_receipt_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handlePaybillTxnPinClose = () => {
    ele.current?.clear();
    setPinError("");
    setTxnPin("");
    setPin("");
    setOtp(["", "", "", ""]);
    setActiveInput(0);
    const modalElement = modalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  const handlePay = () => {
    handleSelectAccountClose();
    const modalElement = document.getElementById("txnPin_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const getBillImage = (billType: string) => {
    switch (billType) {
      case "Electricity":
        return electricityIcon;
      case "Water":
        return waterIcon;
      case "Airtime":
        return reachargeIcon;
      case "Internet":
        return internetIcon;
    }
  };

  const handleEnterKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (txnPin) {
        handleConfirmPayment(payBillData);
      } else {
        setPinError("Pin is required");
      }
    }
  };

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = data?.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);

    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(data?.length / itemsPerPage);
    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  return (
    <>
      <style>
        {`
          @keyframes rotate {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }

          .rotate {
            animation: rotate 1s linear infinite;
          }
        `}
      </style>
      <h4 className="mb-5">Bill Pay Dashboard</h4>
      <div
        className={`d-flex gap-3 ${
          window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
        }`}
      >
        <div className="card d-flex flex-column flex-grow-1">
          <>
            <div className="icon card d-flex flex-column m-5 shadow-sm p-5">
              <div className="d-flex justify-content-between mb-3">
                <div>
                  <p className="fs-3 fw-bold">Favourites</p>
                </div>
                <div className="d-flex align-items-center">
                  {/* <button
                    ref={btnRef}
                    onClick={() => {
                      onClick();
                      fetchBillPayDashboard();
                    }}
                    type="button"
                    className="btn me-10 fs-7"
                    id="kt_button_1"
                    style={{ padding: "3px 10px" }}
                  >
                    <span className="indicator-label">Refresh Due</span>
                    <span className="indicator-progress">
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button> */}
                  {favourites.length > 5 && (
                    <i
                      className={`bi bi-caret-${
                        showAll ? "up" : "down"
                      } fs-1 fw-bold me-5 cursor-pointer`}
                      onClick={handleDropdownClick}
                    ></i>
                  )}
                </div>
              </div>

              {/* Display the first row with the first 4 favourites */}
              <div className="d-flex flex-row justify-content-start mb-8">
                {favourites?.slice(0, 5).map((fav, index) => (
                  <div
                    key={index}
                    className="d-flex flex-column ms-5 me-4 card bg-white  shadow p-3 position-relative"
                    style={{
                      height: "230px",
                      width: "186px",
                      borderRadius: "20px",
                    }}
                  >
                    <div className="position-absolute top start-0.5">
                      <i
                        className={`bi bi-arrow-clockwise fs-3 cursor-pointer ${
                          rotationStates[index] ? "rotate" : ""
                        }`}
                        onClick={() => {
                          refreshBiller(
                            fav.billerType,
                            [fav.billerName],
                            fav.customerId
                          );
                          handleIconClick(index);
                        }}
                      ></i>
                    </div>
                    <Authorize hasAnyPermission={["MENU_BILLPAY|DELETE"]}>
                      <div className="position-absolute top-0.1 end-0">
                        {" "}
                        <Authorize hasAnyPermission={[""]}></Authorize>
                        <i
                          className="bi bi-three-dots text-secondary fs-2 text-end p-2 cursor-pointer"
                          onClick={() => handleThreeDotsClick(index)}
                        ></i>
                        {openCardIndex === index && (
                          <div
                            className="card shadow p-2 cursor-pointer"
                            style={{ position: "absolute" }}
                          >
                            {/* Other small card content */}
                            <div onClick={() => handleDeleteClick(fav)}>
                              Delete
                            </div>
                          </div>
                        )}
                      </div>
                    </Authorize>
                    {/* Small card */}

                    <div className="d-flex justify-content-center align-items-center">
                      {" "}
                      <div
                        className={`bg-light-${
                          fav.billerType.toLowerCase() === "electricity"
                            ? "warning"
                            : fav.billerType.toLowerCase() === "water"
                            ? "water"
                            : fav.billerType.toLowerCase() === "airtime"
                            ? "primary"
                            : "other"
                        } card shadow m-3 d-flex justify-content-center align-items-center`}
                        style={{
                          height: "45px",
                          width: "45px",
                          borderRadius: "50%",
                        }}
                      >
                        <img
                          src={getBillImage(fav.billerType)}
                          alt=""
                          style={{ height: "20px", width: "20px" }}
                        />
                      </div>
                    </div>
                    <br />

                    <div className="d-flex flex-column justify-content-center flex-grow-1">
                      <p className="text-wrap text-center fw-bold m-0 fs-7">
                        {fav?.billerName} | {fav?.customerId}
                      </p>

                      <div className="d-flex flex-row justify-content-center mt-3">
                        <p
                          className={`text-nowrap text-center ${
                            fav?.dueAmount === 0 ? "" : "text-danger"
                          } `}
                        >
                          {fav?.currency}{" "}
                          {fav?.dueAmount.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </p>
                        <p className="text-nowrap text-center ms-2">
                          ({fav?.dueAmount === 0 ? "No due" : "Due"})
                        </p>
                        {/* <div className="" style={{ marginTop: "0.9px" }}>
                         
                        </div> */}
                      </div>
                      <Authorize hasAnyPermission={["MENU_BILLPAY|ADD"]}>
                        <div className="d-flex align-items-center justify-content-center mt-3">
                          <button
                            className="btn ms-5 me-5 d-flex align-items-center justify-content-center fs-7 text-nowrap"
                            style={{ height: "30px", width: "110px" }}
                            onClick={() => {
                              setBillerData({
                                ...billerData,
                                customerName: fav.customerName,
                                customerIdBiller: fav.customerId,
                                billerNames: [fav.billerName],
                                billerType: fav.billerType,
                                amountDue: fav.dueAmount,
                                dueDate: fav.dueDate,
                                billCurrency: fav.currency,
                                billStatus:
                                  fav.billStatus === 1 ? "Unpaid" : "Paid",
                              });
                              handleBillDetails();
                            }}
                          >
                            {" "}
                            {fav?.dueAmount === 0 ? "Pay Advance" : button}
                          </button>
                        </div>
                      </Authorize>
                    </div>
                  </div>
                ))}
              </div>

              {/* Display the second row with the additional favourites if showAll is true */}
              {showAll && (
                <div className="d-flex flex-wrap justify-content-start">
                  {favourites?.slice(5).map((fav, index) => (
                    <div
                      key={index}
                      className="d-flex flex-column ms-5 me-4 mb-8 card bg-white  shadow p-3 position-relative"
                      style={{
                        height: "230px",
                        width: "180px",
                        borderRadius: "20px",
                      }}
                    >
                      <div className="position-absolute top start-0.5">
                        <i
                          className={`bi bi-arrow-clockwise fs-3 cursor-pointer ${
                            rotationStates[index + 5] ? "rotate" : ""
                          }`}
                          onClick={() => {
                            refreshBiller(
                              fav.billerType,
                              [fav.billerName],
                              fav.customerId
                            );
                            handleIconClick(index + 5);
                          }}
                        ></i>
                      </div>
                      <Authorize hasAnyPermission={["MENU_BILLPAY|DELETE"]}>
                        <div className="position-absolute top-0.1 end-0">
                          {" "}
                          <i
                            className="bi bi-three-dots text-secondary fs-2 text-end p-2 cursor-pointer"
                            onClick={() => handleThreeDotsClick(index + 5)}
                          ></i>
                          {openCardIndex === index + 5 && (
                            <div
                              className="card shadow p-2 cursor-pointer"
                              style={{ position: "absolute" }}
                            >
                              {/* Other small card content */}
                              <div onClick={() => handleDeleteClick(fav)}>
                                Delete
                              </div>
                            </div>
                          )}
                        </div>
                      </Authorize>

                      {/* Small card */}

                      <div className="d-flex justify-content-center align-items-center">
                        {" "}
                        <div
                          className={`bg-light-${
                            fav.billerType.toLowerCase() === "electricity"
                              ? "warning"
                              : fav.billerType.toLowerCase() === "water"
                              ? "water"
                              : fav.billerType.toLowerCase() === "airtime"
                              ? "primary"
                              : "other"
                          } card shadow m-3 d-flex justify-content-center align-items-center`}
                          style={{
                            height: "45px",
                            width: "45px",
                            borderRadius: "50%",
                          }}
                        >
                          <img
                            src={getBillImage(fav.billerType)}
                            alt=""
                            style={{ height: "20px", width: "20px" }}
                          />
                        </div>
                      </div>
                      <br />

                      <div className="d-flex flex-column justify-content-center flex-grow-1">
                        <p className="text-wrap text-center fw-bold m-0 fs-7">
                          {fav?.billerName} | {fav?.customerId}
                        </p>

                        <div className="d-flex flex-row justify-content-center mt-3">
                          <p
                            className={`text-nowrap text-center ${
                              fav?.dueAmount === 0 ? "" : "text-danger"
                            } `}
                          >
                            {fav?.currency}{" "}
                            {fav?.dueAmount.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </p>
                          <p className="text-nowrap text-center ms-2">
                            ({fav?.dueAmount === 0 ? "No due" : "Due"})
                          </p>
                          {/* <div className="" style={{ marginTop: "0.9px" }}>
                        
                       </div> */}
                        </div>
                        <Authorize hasAnyPermission={["MENU_BILLPAY|ADD"]}>
                          <div className="d-flex align-items-center justify-content-center mt-3">
                            <button
                              className="btn ms-5 me-5 d-flex align-items-center justify-content-center fs-7 text-nowrap"
                              style={{ height: "30px", width: "110px" }}
                              onClick={() => {
                                setBillerData({
                                  ...billerData,
                                  customerName: fav.customerName,
                                  customerIdBiller: fav.customerId,
                                  billerNames: [fav.billerName],
                                  billerType: fav.billerType,
                                  amountDue: fav.dueAmount,
                                  dueDate: fav.dueDate,
                                  billCurrency: fav.currency,
                                  billStatus:
                                    fav.billStatus === 1 ? "Unpaid" : "Paid",
                                });
                                handleBillDetails();
                              }}
                            >
                              {" "}
                              {fav?.dueAmount === 0 ? "Pay Advance" : button}
                            </button>
                          </div>
                        </Authorize>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="shadow-sm m-5 rounded">
              <div className="py-2 card py-5 px-5">
                <div className="d-flex justify-content-between align-items-center p-3 pt-0">
                  <h4 className="ms mb-0">Recent Transactions</h4>
                  <div className="d-flex align-items-center">
                    <Authorize hasAnyPermission={["MENU_BILLPAY|ADD"]}>
                      <button
                        className="btn fs-5 p-3 px-10"
                        onClick={() => handleSelectBiller()}
                      >
                        Add Biller
                      </button>
                    </Authorize>
                  </div>
                </div>
                <div className="tab-content " id="myTabContent">
                  <div className="card p-2">
                    <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                      <thead className="bg-gray-200">
                        <tr className="text-start text-dark-600 fw-bold fs-6 ">
                          <th className="min-w-100px p-5">Description</th>
                          <th className="min-w-100px p-5">Transcation ID</th>
                          <th className="min-w-100px p-5">Transaction Date</th>
                          <th className="min-w-100px p-5">Transaction Time</th>
                          <th className="min-w-100px p-5 text-nowrap">
                            Amount
                          </th>
                          {/* <th className="min-w-100px p-5 text-nowrap">
                                Action
                              </th> */}
                        </tr>
                      </thead>

                      <tbody>
                        {currentData && currentData.length > 0 ? (
                          currentData.map((item, index) => (
                            <tr
                              key={index}
                              onMouseOver={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "#f1f1f2";
                              }}
                              onMouseOut={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "white";
                                // target.style.transform = "none";
                                // target.style.boxShadow = "none";
                              }}
                              id="kt_modal_toggle_ereceipt"
                              onClick={() => {
                                handleViewClick(item?.txnId);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                                {item.txnTypeName}
                              </td>
                              <td className="p-5 text-gray-600">
                                {item.txnId}
                              </td>
                              <td className="p-5 text-gray-600 fs-6">
                                {item.dateTime.toString().split("|")[0]}
                              </td>
                              <td className="p-5 text-gray-600 fs-6">
                                {item.dateTime.toString().split("|")[1]}
                              </td>
                              <td
                                className={`p-5 ${
                                  item.txnType == "C"
                                    ? "text-primary"
                                    : "text-danger"
                                }`}
                              >
                                {item.currency}{" "}
                                {item.amount.toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}{" "}
                                <i
                                  className={`bi bi-arrow-${
                                    item.txnType == "C"
                                      ? "down-square text-primary"
                                      : "up-square text-danger"
                                  }`}
                                ></i>
                              </td>
                              {/* <td className="p-5 text-gray-600 p-2">
                                  <i
                                    className="bi bi-download p-5 text-primary"
                                    id="kt_receipt_modal_toggle"
                                    // onClick={() => handleDownloadClick(item.txnId)}
                                  ></i>
                                </td> */}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="p-5">
                    <ul className="pagination">
                      <li
                        className={`page-item double-arrow ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                        key={0}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(1)}
                        >
                          <i className="previous"></i>
                          <i className="previous"></i>
                        </a>
                      </li>
                      <li
                        className={`page-item previous ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                        key={1}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          <i className="previous"></i>
                        </a>
                      </li>
                      {renderPageNumbers()}
                      <li
                        className={`page-item next ${
                          currentPage === Math.ceil(data?.length / itemsPerPage)
                            ? "disabled"
                            : ""
                        }`}
                        key={2}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          <i className="next"></i>
                        </a>
                      </li>
                      <li
                        className={`page-item double-arrow ${
                          currentPage === Math.ceil(data?.length / itemsPerPage)
                            ? "disabled"
                            : ""
                        }`}
                        key={3}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() =>
                            handlePageChange(
                              Math.ceil(data?.length / itemsPerPage)
                            )
                          }
                        >
                          <i className="next"></i>
                          <i className="next"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="p-5 d-flex align-items-center">
                    <label
                      htmlFor="itemsPerPage"
                      style={{ fontWeight: "bold" }}
                    >
                      Total: {data?.length}&nbsp;&nbsp;
                    </label>
                    <select
                      id="itemsPerPage"
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                      style={{ padding: "1px 2px 1px 2px", cursor: "pointer" }}
                    >
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={75}>75</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>

      {/* SelectBill Modal */}
      <div
        className="modal"
        tabIndex={-1}
        id="select_biller_modal"
        ref={selectBillerModalRef}
      >
        <div
          className="modal-dialog modal-dialog-centered w-400px"
          style={{ margin: "0 auto" }}
        >
          <div className="modal-content">
            <div className="d-flex flex-row justify-content-between modal-header">
              <label className="form-label fs-3 m-0">Enter Details</label>
              {/* <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                onClick={handleSelectBillerClose}
              >
                <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div> */}
            </div>
            <div
              className="modal-body p-10 "
              style={{
                display: authority === 3 ? "flex" : "",
                minHeight: "200px",
                alignItems: authority === 3 ? "center" : "center",
                justifyContent: authority === 3 ? "center" : "center",
              }}
            >
              {authority === 3 ? (
                <div>
                  <p className="fw-bold text-center text-danger">
                    You don't have access to this feature.
                  </p>

                  <p className="fw-bold text-center text-danger">
                    Please contact admin.
                  </p>
                </div>
              ) : (
                <Formik
                  innerRef={formikRef}
                  initialValues={{
                    billerType: "",
                    operator: "",
                    customerID: "",
                  }}
                  validationSchema={Yup.object().shape({
                    billerType: Yup.string().required(
                      "Biller type is required"
                    ),
                    operator: Yup.string().required("Operator is required"),
                    customerID: Yup.string()
                      .matches(
                        /^[a-zA-Z0-9 ]+$/,
                        "Only letters and numbers are allowed"
                      )
                      .required("Customer ID is required"),
                  })}
                  onSubmit={(values) => {
                    fetchBillerData(
                      values?.billerType,
                      [values?.operator],
                      values?.customerID
                    );
                    handleBillDetails();
                  }}
                >
                  {(formik) => (
                    <>
                      <Form>
                        <div className="form-group">
                          <label className="form-label">
                            Choose type of Biller
                          </label>
                          <Field
                            as="select"
                            name="billerType"
                            className={`form-select custom-input ${
                              formik.errors.billerType &&
                              formik.touched.billerType
                                ? "is-invalid"
                                : ""
                            }`}
                            data-control="select2"
                            placeholder="Select an option"
                            // style={{ padding: "6.5px" }}
                            onChange={(
                              event: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              formik.setFieldValue(
                                "billerType",
                                event.target.value
                              );
                              setBillerType(event.target.value);
                              fetchBillers(event.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Biller
                            </option>
                            {menuNames.map((item) => (
                              <option
                                value={item?.menuName}
                                key={item?.menuCode}
                              >
                                {item?.menuName}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="billerType"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="form-group  mt-5">
                          <label className="form-label">Choose Operator</label>
                          <Field
                            as="select"
                            name="operator"
                            className={`form-select ${
                              formik.errors.operator && formik.touched.operator
                                ? "is-invalid"
                                : ""
                            }`}
                            data-control="select2"
                            placeholder="Select an option"
                            // style={{ padding: "6.5px" }}
                            onChange={(
                              event: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              formik.setFieldValue(
                                "operator",
                                event.target.value
                              );
                              setOperator(event.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select operator
                            </option>
                            {billers.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="operator"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="form-group mt-5">
                          <label className="form-label">
                            Enter Customer ID
                          </label>
                          <Field
                            type="text"
                            name="customerID"
                            className={`form-control custom-input ${
                              formik.errors.customerID &&
                              formik.touched.customerID
                                ? "is-invalid"
                                : ""
                            }`}
                            // style={{ padding: "6.5px" }}
                            maxLength={20}
                          />

                          <ErrorMessage
                            name="customerID"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="d-flex flex-row mt-5">
                          <button
                            type="submit"
                            className="btn rounded me-5"
                            style={{
                              width: "180px",
                            }}
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary rounded"
                            style={{
                              width: "180px",
                              color: "#246bfb",
                            }}
                            onClick={() => handleSelectBillerClose()}
                          >
                            Cancel
                          </button>
                        </div>
                      </Form>
                    </>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* BillDetails Modal */}
      <div
        className="modal"
        tabIndex={-2}
        id="bill_details_modal"
        ref={BillDetailsModalRef}
      >
        <div
          className="modal-dialog modal-dialog-centered w-400px"
          style={{ margin: "0 auto" }}
        >
          <div className="modal-content">
            <div className="d-flex flex-row justify-content-between modal-header">
              <label className="form-label fs-3 m-0">Bill Details</label>
              {/* <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                onClick={handleBillDetailsClose}
              >
                <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div> */}
            </div>
            <div
              className="modal-body p-10"
              style={{
                display: authority === 3 ? "flex" : "",
                minHeight: "300px",
                alignItems: authority === 3 ? "center" : "center",
                justifyContent: authority === 3 ? "center" : "center",
              }}
            >
              {authority === 3 ? (
                <div>
                  <p className="fw-bold text-center text-danger">
                    You don't have access to this feature.
                  </p>

                  <p className="fw-bold text-center text-danger">
                    Please contact admin.
                  </p>
                </div>
              ) : (
                <>
                  <div className="d-flex justify-content-center">
                    <img src={getBillImage(billerType)} />
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between mb-2">
                    <div className="d-flex align-items-center">
                      <p className="d-flex">Due Amount</p>
                      {/* <i className="bi bi-arrow-clockwise text-dark m-0"></i> */}
                    </div>
                    <div className="fw-bold">
                      {billerData?.billCurrency}{" "}
                      {billerData?.amountDue.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </div>
                  </div>
                  {billerData?.dueDate && (
                    <div className="d-flex justify-content-between mb-2">
                      <div>
                        <p>Due Date</p>
                      </div>
                      <div className="fw-bold">{billerData?.dueDate}</div>
                    </div>
                  )}

                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      <p>Service Provider</p>
                    </div>
                    <div>
                      <p className="fw-bold">{billerData?.billerNames}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      <p>Customer Name</p>
                    </div>
                    <div>
                      <p className="fw-bold">{billerData?.customerName}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      <p>Customer ID</p>
                    </div>
                    <div>
                      <p className="fw-bold">{billerData?.customerIdBiller}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      <p>Status</p>
                    </div>
                    <div>
                      <p
                        className={`badge badge-light-${
                          billerData.billStatus === "Paid"
                            ? "success"
                            : "danger"
                        }`}
                      >
                        {billerData.billStatus}
                      </p>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn rounded ms-10 mt-3"
                    style={{ width: "250px" }}
                    onClick={handleSelectAccount}
                    disabled={billerData.billStatus === "Paid"}
                  >
                    Continue
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* SelectAccount Modal */}
      <div
        className="modal"
        tabIndex={-3}
        id="select_account_modal"
        ref={SelectAccountModalRef}
      >
        <div
          className="modal-dialog modal-dialog-centered w-400px"
          style={{ margin: "0 auto" }}
        >
          <div className="modal-content">
            <div className="d-flex flex-row justify-content-between modal-header">
              <label className="form-label fs-3 m-0">Select Account</label>
              {/* <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                onClick={handleSelectAccountClose}
              >
                <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div> */}
            </div>
            <div className="modal-body p-10">
              <ul className="list-group border-0">
                {accounts
                  ?.filter((item) => item.currency !== "USD")
                  .map((item, index) => (
                    <>
                      <li
                        className={`list-group-item shadow-sm mb-5 p-4 rounded d-flex flex-row ${
                          billerData.amountDue > item.balance
                            ? "border border-1 border-danger"
                            : "border border-1 border-primary"
                        }`}
                      >
                        <div className="w-10 d-flex me-2">
                          <input
                            className="form-check-input me-1"
                            type="radio"
                            name="listGroupRadio"
                            value={item?.balance}
                            id={index?.toString()}
                            onChange={(e) => {
                              setSelectedRadio(+e.target.value);
                              setSelectedIndex(index);
                              if (billerData.amountDue > +e.target.value) {
                                setLowBalErr(
                                  "Low balance! Select another account."
                                );
                              } else {
                                setLowBalErr("");
                              }
                            }}
                            checked={selectedIndex === index}
                          />
                        </div>
                        <div className="d-flex flex-grow-1 justify-content-between">
                          <label
                            className="form-check-label text-dark fw-bold"
                            htmlFor={index?.toString()}
                          >
                            {item?.accountNumber}
                          </label>
                          <p
                            className={`m-0 text-${
                              item.balance < billerData?.amountDue
                                ? "danger"
                                : "primary"
                            } fw-bold`}
                          >
                            {item?.currency +
                              " " +
                              item?.balance.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                          </p>
                        </div>
                      </li>
                      {selectedIndex === index && lowBalErr && (
                        <p className="text-danger text-center">{lowBalErr}</p>
                      )}
                    </>
                  ))}
              </ul>

              {button === "Initiate" ? (
                <div className="mb-5">
                  <label className="form-label">Select Approver</label>
                  <select
                    id="approver"
                    name="approver"
                    className="form-select"
                    onChange={handleApprover}
                  >
                    <option value="">Select Approver</option>
                    {approvers.map((approv, key) => (
                      <option value={approv.userGuid}>{approv.name}</option>
                    ))}
                  </select>
                  {apperrMsg && (
                    <p className="text-danger">
                      Please select an approver to pay
                    </p>
                  )}
                </div>
              ) : null}

              <button
                type="button"
                className="btn rounded ms-10"
                style={{ width: "250px" }}
                disabled={billerData.amountDue > selectedRadio}
                onClick={() => {
                  if (button === "Initiate" && !approverGuid)
                    setAppErrMsg(true);
                  else handlePay();
                }}
              >
                {button}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* PinInput Modal */}
      <div
        className="modal fade"
        tabIndex={-1}
        id="txnPin_modal"
        ref={modalRef}
      >
        <div
          className="modal-dialog modal-dialog-centered w-400px"
          style={{ margin: "0 auto" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex flex-row justify-content-between">
                <h1
                  className="modal-title text-dark"
                  style={{ paddingLeft: "100px" }}
                >
                  Enter Your PIN
                </h1>
                {/* <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                </div> */}
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center p-5">
                <div className="d-flex align-items-center justify-content-center">
                  <p className="text-center fs-5 mb-5">
                    Enter your PIN to confirm
                  </p>
                </div>
                <div className="d-flex flex-column justify-content-center mb-7">
                  <OTPInput
                    // {...field}
                    value={otp.join("")}
                    onChange={(value: string) => {
                      value
                        .split("")
                        .forEach((char, index) => handleChange(char, index));
                    }}
                    numInputs={4}
                    renderSeparator={<span>&nbsp;&nbsp;</span>}
                    renderInput={(props, index) => (
                      <input
                        {...props}
                        value={otp[index]}
                        ref={(el) => (inputRefs.current[index] = el)}
                        onChange={(e) => {
                          const pin = handleChange(e.target.value, index);
                          setTxnPin(pin);
                          setPinError("");
                        }}
                        onKeyDown={(e) => {
                          const pin = handleKeyDown(e, index);
                          handleEnterKeyPress(e);
                          setTxnPin("");
                          setPinError("");
                        }}
                        type={show ? "password" : "text"}
                        autoFocus={true}
                        disabled={index !== activeInput}
                        onFocus={() => setActiveInput(index)}
                        className="text-center rounded mt-3 border border-1 border-dark mx-auto"
                        style={{ height: "50px", width: "50px" }}
                      />
                    )}
                  />
                  <p
                    className="text-center cursor-pointer text-decoration-underline mb-0 mt-2"
                    onClick={() => setShow(!show)}
                  >
                    {show ? "Show PIN" : "Hide PIN"}
                  </p>
                </div>
                {pinError && <p className="text-danger">{pinError}</p>}
                <div className="d-flex gap-5">
                  <button
                    type="submit"
                    className="btn rounded"
                    style={{
                      width: "150px",
                      color: "#ffff",
                    }}
                    onClick={() => {
                      if (txnPin.length === 4)
                        handleConfirmPayment(payBillData);
                      else {
                        if (!txnPin) setPinError("Pin is required");
                        else setPinError("Pin must be 4 digits");
                      }
                    }}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary rounded"
                    data-bs-dismiss="modal"
                    style={{
                      width: "150px",
                      color: "#246bfd",
                    }}
                    onClick={handlePaybillTxnPinClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EReceiptModal data={response} />
      <BillPayReceipt data={selectedData} />
    </>
  );
};

export default Paybill;
